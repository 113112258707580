/**
 * @file implicit SDK Auto generated JS module.
 * @see https://github.com/meena-erian/implicit-sdk
 * @author Menas (Meena) Erian
 * @copyright (C) 2022 Menas (Meena) Erian
 */

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getCsrfToken() {
  return getCookie('csrftoken');
}

var call = {
  timeout: -1,
  stack: [],
  send: function() {
    if (call.stack.length) {
      let s = call.stack;
      call.stack = [];
      var headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-CSRFToken': getCsrfToken(),
      };
      if (document.location.host === 'localhost:3000' && localStorage.getItem('Authorization')) {
        headers['Authorization'] = localStorage.getItem('Authorization');
      }
      fetch('https://backend.exposify.net/api/client?type=api', {
        headers: headers,
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(s),
      }).then(
        async r => call.resolve(s, r),
        async r => call.reject(s, r),
      );
    }
  },
  resolve: async function(callStack, serverResponse) {
    if ('Authorization' in serverResponse.headers) {
      localStorage.setItem('Authorization', serverResponse.headers['Authorization']);
    }
    serverResponse = await serverResponse.json();
    serverResponse.forEach((element, i) => {
      if ('returned' in element) {
        callStack[i].promise.resolve(element.returned);
      } else {
        callStack[i].promise.reject(element.exception);
      }
    });
  },
  reject: function(callStack) {
    callStack.forEach(c => {
      c.promise.reject('Connection failed');
    });
  },
};

/**
 * Confirm user email
 * @param {string} uidb64 The user ID encoded in base64
 * @param {string} token The verification token
 * @return {boolean} True in case of successful activation, False otherwise
 */

async function confirm_email(uidb64, token) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'confirm_email',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { confirm_email };

/**
 * Confirm password reset operation and set the new password
 * @param {string} uidb64 The user ID encoded in base64
 * @param {string} token The verification token
 * @param {string} password The new user password
 * @return {boolean} True in case of successful activation, False otherwise
 */

async function confirm_password_reset(uidb64, token, password) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'confirm_password_reset',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { confirm_password_reset };

/**
 * Get information of currently authenticated user
 * @return {object|False} dict of the current user if any or False otherwise
 */

async function get_auth_user() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'get_auth_user',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { get_auth_user };

/**
 * A function to persistently authenticate a user
 * @param {string} email the username or email
 * @param {string} password the user password
 * @return {object} a dict of the user details
 */

async function login(email, password) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'login',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { login };

/**
 * Clear any session data
 */

async function logout() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'logout',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { logout };

/**
 * Get a list of the registered domain names of
 *
 * the currently authenticated user
 * @return {object|null} List of dicts describing the domain names
 * added by the currently authenticated user or None if
 * requested without an authenticated user
 */

async function my_domains() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'my_domains',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { my_domains };

/**
 * Request a password reset email
 * @param {string} email username or email of the user
 */

async function request_password_reset(email) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'request_password_reset',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { request_password_reset };

/**
 * Register a new user
 * @param {string} email email address of the new user
 * @param {string} password new password for the new user
 * @return {object} Returns a dict containing the response code and
 * HTML string rendering the response of the server.
 */

async function signup(name, email, password) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function(resolve, reject) {
    call.stack.push({
      name: 'signup',
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export { signup };
