import React, { useEffect } from 'react';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const VerifyAccount = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { complete_account_activation } = useSelector(({ auth }) => auth);
  const { uidb64, token } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(AuhMethods[method].onAccountActivation({ uidb64, token }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (complete_account_activation === false) {
      setTimeout(() => {
        history.push('/');
      }, 5000);
    } else if (complete_account_activation === true) {
      setTimeout(() => {
        history.push('/signin');
      }, 5000);
    }
    console.log('complete_account_activation: ', complete_account_activation);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete_account_activation]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      {complete_account_activation === true && (
        <Box className={classes.authContent}>
          <Box mb={7}>
            <CmtImage src={'/images/logo.png'} />
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            <IntlMessages id="appModule.accountactivated" />
          </Typography>
          <Typography className={classes.textAcc}>
            <NavLink to="/signin">
              <IntlMessages id="appModule.signin" />
            </NavLink>
          </Typography>
          {dispatch(AuhMethods[method].getSocialMediaIcons())}
          <ContentLoader />
        </Box>
      )}
      {complete_account_activation === false && (
        <Box className={classes.authContent}>
          <Box mb={7}>
            <CmtImage src={'/images/logo.png'} />
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Invalid Activation Link
          </Typography>
          <Typography className={classes.textAcc}>
            <NavLink to="/signin">
              <IntlMessages id="appModule.signin" />
            </NavLink>
          </Typography>
          {dispatch(AuhMethods[method].getSocialMediaIcons())}
          <ContentLoader />
        </Box>
      )}
    </AuthWrapper>
  );
};

export default VerifyAccount;
