import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, IconButton, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { useParams, useHistory } from 'react-router-dom';
import { setPasswordResetCompleted } from 'redux/actions/Auth';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { complete_password_reset } = useSelector(({ auth }) => auth);
  const { error } = useSelector(({ common }) => common);
  const classes = useStyles({ variant });
  const { uidb64, token } = useParams();
  const history = useHistory();
  const valid = password === password2;

  useEffect(() => {
    let timeOutStopper = null;
    if (complete_password_reset) {
      setOpen(true);
      console.log('complete_password_reset');
      timeOutStopper = setTimeout(() => {
        dispatch(setPasswordResetCompleted(false));
        history.push('/signin');
      }, 5000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete_password_reset]);

  useEffect(() => {
    if (error.length) {
      console.log('Link Failed. Redirecting in 5 sec');
      setTimeout(() => {
        history.push('/forgot-password');
      }, 5000);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = () => {
    dispatch(AuhMethods[method].onPasswordReset({ uidb64, token, password }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Set Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            Your password was successfully updated!
          </Alert>
        </Collapse>
        <form>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              error={!valid}
              type="password"
              label={<IntlMessages id="appModule.confirmpassword" />}
              fullWidth
              onChange={event => setPassword2(event.target.value)}
              defaultValue={password2}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={valid ? '' : "Passwords Doesn't Match"}
            />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={!valid}>
              <IntlMessages id="appModule.update" />
            </Button>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
