import {
  SEND_FORGET_PASSWORD_EMAIL,
  SEND_ACCOUNT_ACTIVATION_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  COMPLETE_ACCOUNT_ACTIVATION,
  COMPLETE_PASSWORD_RESET,
} from '../../@jumbo/constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const setPasswordResetCompleted = status => {
  return dispatch => {
    dispatch({
      type: COMPLETE_PASSWORD_RESET,
      payload: status,
    });
  };
};

export const setAccountActivationCompleted = status => {
  return dispatch => {
    dispatch({
      type: COMPLETE_ACCOUNT_ACTIVATION,
      payload: status,
    });
  };
};

export const setAccountActivationMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_ACCOUNT_ACTIVATION_EMAIL,
      payload: status,
    });
  };
};
