import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthUser,
  setForgetPassMailSent,
  setAccountActivationMailSent,
  setAccountActivationCompleted,
  setPasswordResetCompleted,
  updateLoadUser,
} from '../../../redux/actions/Auth';
import React from 'react';
import {
  signup,
  login,
  logout,
  get_auth_user,
  request_password_reset,
  confirm_password_reset,
  confirm_email,
} from '../../../serverbackend';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        var ret = await signup(name, email, password);
        if (ret.code === 'SUCCESS') {
          dispatch(setAccountActivationMailSent(true));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(ret.html));
        }
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  onLogin: ({ email, password }) => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        const ret = await login(email, password);
        if ('user' in ret) {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(ret.user));
        } else {
          console.log(ret);
          dispatch(fetchError(ret.error));
        }
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },
  onLogout: () => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        await logout();
        dispatch(fetchSuccess());
        dispatch(setAuthUser(null));
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  getAuthUser: (loaded = false) => {
    return async dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      try {
        const user = await get_auth_user();
        dispatch(fetchSuccess());
        dispatch(setAuthUser(user));
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  onForgotPassword: ({ email }) => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        var ret = await request_password_reset(email);
        if ('code' in ret && ret.code === 'SUCCESS') {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        } else {
          var err = ret.html || 'Some Error Occured';
          dispatch(fetchError(err));
        }
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  onPasswordReset: ({ uidb64, token, password }) => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        if (await confirm_password_reset(uidb64, token, password)) {
          dispatch(setPasswordResetCompleted(true));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError('Invalid or Expired Link'));
        }
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  onAccountActivation: ({ uidb64, token }) => {
    return async dispatch => {
      dispatch(fetchStart());
      try {
        if (await confirm_email(uidb64, token)) {
          dispatch(setAccountActivationCompleted(true));
          dispatch(fetchSuccess());
        } else {
          dispatch(setAccountActivationCompleted(false));
          dispatch(fetchError('Invalid or Expired Link'));
        }
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
